
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Action } from 'vuex-class';
  // @ts-ignore
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import FileSaver from 'file-saver';
  import Modal from '@/components/common/Modal.vue';
  import { firebaseAxiosInstance } from '@/boot/axios';

  @Component({
    components: {
      Modal,
    },
  })
  export default class DownloadPDFModal extends Vue {
    @Prop() user!: any;

    @Action(addToastMessage) addToastMessage!: Function;

    currentYear: number = new Date().getFullYear();
    selectedYear: number = this.currentYear;
    selectedLang: 'nl' | 'en' = 'nl';
    availableLangs = ['nl', 'en'];

    // Determines the number of years shown in the tax statement year selector
    yearsToShow: number = 3;
    isProcessing = false;

    get years(): number[] {
      return Array.from({ length: this.yearsToShow }, (v, i): number => this.currentYear - i);
    }

    async downloadPDF() {
      this.isProcessing = true;
      const instance = await firebaseAxiosInstance();

      try {
        const res = await instance.get(
          'generateDividendsPDF',
          {
            params: {
              year: this.selectedYear,
              userId: this.user.id,
              lang: this.selectedLang,
            },
            responseType: 'blob',
          },
        );

        FileSaver.saveAs(res.data, `statement_${this.selectedYear}_${this.user.name}_${this.user.surname}.pdf`);
      } catch (err) {
        // As the error will be of type Blob we need to parse it to text first
        // see https://developer.mozilla.org/en-US/docs/Web/API/Blob/text
        let errorText;

        try {
          errorText = await err.response.data.text();
        } catch (e) {
          errorText = 'There was a problem generating the PDF.';
        }

        this.addToastMessage({
          text: errorText,
          type: 'danger',
        });
      }

      this.isProcessing = false;
    }
  }
