
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
  import { Action, State as StateClass } from 'vuex-class';
  // @ts-ignore
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { State } from '@/models/State';
  import { DataContainerStatus } from '@/models/Common';
  import { Investor } from '@/models/users/User';
  import Modal from '@/components/common/Modal.vue';

  @Component({
    components: {
      Modal,
    },
  })
  export default class DeleteInvestortModal extends Vue {
    @Prop({ default: undefined }) investor!: Investor;

    @Action(addToastMessage) addToastMessage!: Function;
    @Action hardDeleteInvestor!: Function;
    @StateClass('user') stateInvestor!: State['user'];

  @Watch('stateInvestor.status')
    onstateInvestorChange(assetStatus: DataContainerStatus): void {
      // Error
      if (assetStatus === DataContainerStatus.Error) {
        this.addToastMessage({
          text: this.stateInvestor?.error,
          type: 'danger',
        });
      }

      // Success
      if (assetStatus === DataContainerStatus.Success) {
        // Notification
        this.addToastMessage({
          text: 'The investor has been successfully removed.',
          type: 'success',
        });
      }

      if (assetStatus !== DataContainerStatus.Processing) {
        this.$emit('close');
      }
    }

    get isProcessing(): boolean {
      return this.stateInvestor?.status === DataContainerStatus.Processing;
    }
  }
