
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { Action, State as StateClass } from 'vuex-class';
  // @ts-ignore
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { ModelSelect } from 'vue-search-select';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { DataContainerStatus } from '@/models/Common';
  import { State } from '@/models/State';
  import { bloqifyFirestore } from '@/boot/firebase';
  import { convertLocalDateToUTC, timestampToDate } from '@/filters/date';
  import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
  import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
  import { Investor, isInvestor, User } from '@/models/users/User';
  import { Payment } from '@/models/investments/Investment';
  import { Asset } from '@/models/assets/Asset';
  import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';

  type SelectOptions = { value: string, text: string };

  @Component({
    components: {
      ModelSelect,
      FormInput,
      FormDatePicker,
      FormInvalidMessage,
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class CreatePayment extends Vue {
    amount: number | null = null;
    FormIcons = FormIcons;
    dateOfPayment: Date | null = null;
    endDateOfPayment: Date | null = null;
    interestRate: number | null = null;
    selectedAsset: SelectOptions = {
      value: '',
      text: '',
    };
    selectedInvestor: SelectOptions = {
      value: '',
      text: '',
    };
    selectedDividendsFormatYear: [string, number] | null = null;
    loadingPromise: Promise<any> = Promise.resolve();

    @Action(addToastMessage) addToastMessage!: Function;
    @Action resetFilters!: (data: { collection: string }) => void;
    @Action bindFirestoreReferences!: Function;
    @Action unbindFirestoreReferences!: Function;
    @Action createPayment!: (payment: any) => void;
    @Action updatePayment!: (payment: any) => void;

    @StateClass payment!: State['payment'];
    @StateClass identificationSettings!: State['identificationSettings'];
    @StateClass boundAssets!: State['boundAssets'];
    @StateClass boundUsers!: State['boundUsers'];
    @StateClass boundInvestment!: State['boundInvestment'];
    @StateClass boundPayment!: State['boundPayment'];

    mounted(): void {
      this.loadingPromise = this.bindFirestoreReferences([
        {
          name: 'boundAssets',
          ref: bloqifyFirestore.collection('assets')
            .where('deleted', '==', false)
            .orderBy('name', 'asc')
            .orderBy('createdDateTime', 'asc'),
        },
        {
          name: 'boundUsers',
          ref: bloqifyFirestore.collection('investors')
            .orderBy('createdDateTime', 'asc'),
        },
      ]);
    }

    beforeDestroy(): void {
      // Clear filters if we are leaving the 'investments' domain
      if (!this.$route.fullPath.includes('investments')) {
        this.resetFilters({ collection: 'investments' });
      }

      const unbindRefs = [
        { name: 'boundAssets' },
        { name: 'boundUsers' },
      ];
      if (this.boundPayment) {
        unbindRefs.push({ name: 'boundPayment' });
        unbindRefs.push({ name: 'boundInvestment' });
      }

      this.unbindFirestoreReferences(unbindRefs);
    }

    @Watch('selectedAsset')
    onNewSelectedAsset(): void {
      this.selectedDividendsFormatYear = null;
    }

    @Watch('payment.status')
    onNewCreatePaymentStatus(newStatus: DataContainerStatus, oldStatus: DataContainerStatus): void {
      if (newStatus === DataContainerStatus.Success) {
        this.addToastMessage({
          text: `Payment correctly ${this.investmentId ? 'modified' : 'created'}`,
          type: 'success',
        });

        const { investmentId: newInvestmentId, paymentId: newPaymentId } = this.payment!.payload as any;

        if (!this.investmentId || this.investmentId !== newInvestmentId || this.paymentId !== newPaymentId) {
          this.$router.replace(`/investments/create-modify-payment/${newInvestmentId}/${newPaymentId}`);
        }
      } else if (newStatus === DataContainerStatus.Error) {
        this.addToastMessage({
          text: this.payment!.error?.message,
          type: 'danger',
        });
      }
    }

    @Watch('investmentId', { immediate: true })
    onIdChange(newId: string): void {
      if (newId && this.paymentId) {
        this.bindFirestoreReferences([
          {
            name: 'boundInvestment',
            ref: bloqifyFirestore.collection('investments').doc(newId),
          },
          {
            name: 'boundPayment',
            ref: bloqifyFirestore.collection('investments').doc(newId)
              .collection('payments').doc(this.paymentId),
          },
        ]);
      }
    }

    @Watch('boundPayment', { immediate: true })
    onNewPayment(newPayment: Payment | null): void {
      if (newPayment) {
        this.bindData(newPayment);
      }
    }

    @Watch('boundInvestment.investor.id', { immediate: true })
    onNewInvestorId(newId: string | undefined): void {
      if (newId) {
        this.selectedInvestor.value = newId;
      }
    }

    @Watch('boundInvestment.asset.id', { immediate: true })
    onNewAssetId(newId: string | undefined): void {
      if (newId) {
        this.selectedAsset.value = newId;
      }
    }

    get investmentId(): string {
      return this.$route.params.investmentId;
    }

    get paymentId(): string {
      return this.$route.params.paymentId;
    }

    get dateOfPaymentRules(): any {
      /**
       * End Date Time.
       * @return Timestamp
       */
      const selectedAssetEndDateTime = (): number | undefined => {
        if (this.selectedAssetObject?.endDateTime !== undefined) {
          return timestampToDate(this.selectedAssetObject?.endDateTime)?.getTime();
        }
        return 0;
      };
      /**
       * Start Date.
       * @return Timestamp
       */
      const selectedAssetStartDateTime = (): number | undefined => {
        if (this.selectedAssetObject?.startDateTime !== undefined) {
          return timestampToDate(this.selectedAssetObject?.startDateTime)?.getTime();
        }
        return 0;
      };
      const timeStampDateOfPayment = (): number | undefined => this.dateOfPayment?.getTime();

      return {
        required: true,
        aftereqtimestamp: {
          baseDate: selectedAssetStartDateTime(),
          otherDate: timeStampDateOfPayment(),
        },
        beforeeqtimestamp: {
          baseDate: selectedAssetEndDateTime(),
          otherDate: timeStampDateOfPayment(),
        },
      };
    }

    get selectedAssetObject(): Asset | undefined {
      return this.boundAssets.find((asset): boolean => asset.id === this.selectedAsset.value);
    }

    get selectedInvestorObject(): User | undefined {
      return this.boundUsers.find((investor): boolean => investor.id === this.selectedInvestor.value);
    }

    get assetOptions(): SelectOptions[] {
      return this.boundAssets.map((asset): SelectOptions => ({ value: asset.id!, text: asset.name }));
    }

    get investorOptions(): SelectOptions[] {
      return this.boundUsers.filter(isInvestor).map((investor): SelectOptions => ({
        value: investor.id!,
        text: `#${investor.customId} ${investor.surname}`,
      }));
    }

    get isPremiumSelected(): boolean {
      return !!this.selectedAssetObject?.premium;
    }

    /**
     * Generating a select array of options depending on if there are any available slots.
     * This would mean the asset has been already closed.
     */
    get dividendsReturnsOptions(): [string, number][] {
      return (this.selectedAssetObject && this.selectedAssetObject.dividendsFormat.map((dF): [string, number] => dF.contents)) || [];
    }

    get saveLoading(): boolean {
      return this.payment?.status === DataContainerStatus.Processing;
    }

    bindData(payment: Payment): void {
      this.amount = payment.providerData.metadata.euroAmount;
      this.selectedDividendsFormatYear = payment.dividendsFormat;

      const paymentTimestamp = (payment.paymentDateTime || payment.createdDateTime);
      this.dateOfPayment = paymentTimestamp.toDate();

      this.endDateOfPayment = payment.endDateTime?.toDate() || null;
      this.interestRate = payment.dividendsFormat[1] || null;
    }

    // Reseting a selected field
    resetSelect(type: string): void {
      this[type] = {
        value: '',
        text: '',
      };
    }

    submitPayment(): void {
      // TODO: add extra validation before submitting
      const payment: { [key: string]: any } = {
        amount: Number(this.amount),
        assetId: this.selectedAsset.value,
        investorId: this.selectedInvestor.value,
        paymentDateTime: this.dateOfPayment ? convertLocalDateToUTC(this.dateOfPayment, true)!.getTime() : null,
        // The following fields will only be submitted if it is a payment modification
        ...(this.investmentId && this.paymentId && {
          investmentId: this.investmentId,
          paymentId: this.paymentId,
        }),
        ...(this.selectedAssetObject?.premium && this.endDateOfPayment && { endDateTime: convertLocalDateToUTC(this.endDateOfPayment) }),
        ...(this.selectedAssetObject?.premium && {
          type: 'loan',
        }),
      };

      // It needs to be checked if this condition is necessary or a premium fund implies non-fixed dividends
      if (!this.selectedAssetObject?.fixedDividends && !this.selectedAssetObject?.premium) {
        payment.dividendsFormat = this.selectedDividendsFormatYear;
      }

      if (this.selectedAssetObject?.premium) {
        // Naive way to caclulate years
        const years = (this.endDateOfPayment as Date).getFullYear() - (this.dateOfPayment as Date).getFullYear();
        payment.dividendsFormat = [years.toString(), Number(this.interestRate)];
      }

      if (this.investmentId && this.paymentId) {
        this.updatePayment(payment);
        return;
      }

      this.createPayment(payment);
    }
  }
