
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import { Action, Getter, State as StateClass } from 'vuex-class';
  import FileSaver from 'file-saver';
  import pick from 'lodash.pick';
  // @ts-ignore
  import json2csv from 'json2csv/dist/json2csv.umd';
  import { Event } from 'vue-tables-2';
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import to from 'await-to-js';
  import moment from 'moment';
  import { ManagerRole } from '@/models/manager/Manager';
  import { DataChangeRequest, Investor, User, UserStatus, UserTier } from '@/models/users/User';
  import { IdentificationRequest, IdentificationRequestStatus } from '@/models/identification-requests/IdentificationRequest';
  import DownloadPDFModal from '@/components/users/DownloadPDFModal.vue';
  import { Pescheckv3PescheckDocument, PeschecScreeningStatus, UserData } from '@/models/identification-requests/pescheck';
  import PesCheckModal, { PescheckDisplay } from '@/components/users/PesCheckModalPep.vue';
  import ModifyStatusModal from '@/components/users/ModifyStatusModal.vue';
  import ChangesRequestedModal from '@/components/users/ChangesRequestedModal.vue';
  import DeleteInvestordModal from '@/components/users/DeleteInvestorModal.vue';
  import { GetUserIdentificationStatus } from '@/store/modules/user';
  import { State } from '@/models/State';
  import { CurrentManager } from '@/models/manager/CurrentManager';
  import { bloqifyFirestore } from '@/boot/firebase';
  import { DataContainerStatus } from '@/models/Common';

  type TierFilter = UserTier | 'all';

  interface UserDisplayTable extends Omit<| Investor, 'pescheck'> {
    id: any;
    pescheck: PescheckDisplay | undefined;
    userIRstatus: GetUserIdentificationStatus;
  }

  function deepValues(object: object, fields?: string[], deep: boolean = false): string | string[] {
    const array: string[] = Object.values(fields?.length ? pick(object, fields) : object).map((value: any) => {
      if (Array.isArray(value)) {
        return value;
      }

      if (typeof value === 'object' && value !== null && value !== undefined) {
        return deepValues(value, [], true);
      }

      return value;
    });

    if (deep) {
      return array;
    }

    return array.flat().join(' ').toLowerCase();
  }

  @Component({
    components: {
      PesCheckModal,
      DownloadPDFModal,
      ModifyStatusModal,
      ChangesRequestedModal,
      DeleteInvestordModal,
    },
  })
  export default class TabAllInvestors extends Vue {
    columns = [
      'customId',
      'name',
      'surname',
      'email',
      'tier',
      'userIRstatus',
      'blockchain',
      'pescheck',
      'createdDateTime',
      'changeRequests',
      'dropdown',
    ];
    combinedQueryFields = ['name', 'surname', 'email', 'tier', 'userIRstatus'];
    options = {
      headings: {
        customId: 'id',
        name: 'Name',
        surname: 'surname',
        email: 'Email',
        tier: 'Tier',
        userIRstatus: 'Status',
        blockchain: 'Blockchain',
        pescheck: 'PES',
        createdDateTime: 'Registered At',
        changeRequests: 'Request',
        dropdown: '',
      },
      descOrderColumns: ['changeRequests'],
      filterable: [
        'customId',
        'name',
        'surname',
        'email',
        'tier',
        'userIRstatus',
        'pescheck',
        'createdDateTimeString',
        'changeRequests',
        'combinedValues',
      ],
      // columnsClasses strings need to have a space at the end
      // because vue-tables-2 adds classes runtime without a space before
      columnsClasses: {
        customId: 'table__col--customId table__col--xs ',
        name: 'table__col--name table__col--s ',
        surname: 'table__col--surname table__col--s ',
        email: 'table__col--email table__col--l ',
        userIRstatus: 'table__col--userIRstatus table__col--s ',
        blockchain: 'table__col--blockchain table__col--s ',
        pescheck: 'table__col--pescheck table__col--xs ',
        tier: 'table__col--tier table__col--xs ',
        createdDateTime: 'table__col--createdDateTime table__col--m ',
        changeRequests: 'table__col--changeRequests table__col--s ',
        dropdown: 'table__col--dropdown align-middle table__col--xs ',
      },
      skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table,
      customFilters: [
        {
          name: 'tier',
          callback(row, query) {
            return row.tier === query;
          },
        },
      ],
      filterAlgorithm: {
        combinedValues(row: any, queryString: string) {
          const queryArray = queryString.toLowerCase().split(' ');
          return queryArray.every((queryWord) => row.combinedValues.includes(queryWord));
        },
      },
      customSorting: {
        userIRstatus(ascending) {
          return (a, b) => {
            if (a === b) {
              return 0;
            }
            const sortingOrder: Array<GetUserIdentificationStatus | UserStatus.Disabled> = [
              UserStatus.Disabled,
              IdentificationRequestStatus.Rejected,
              GetUserIdentificationStatus.Error,
              GetUserIdentificationStatus.None,
              IdentificationRequestStatus.Initial,
              IdentificationRequestStatus.Approved,
            ];
            const aToPass = a.status === UserStatus.Disabled ? UserStatus.Disabled : a.userIRstatus;
            const bToPass = b.status === UserStatus.Disabled ? UserStatus.Disabled : b.userIRstatus;
            if (ascending) {
              return sortingOrder.indexOf(aToPass) > sortingOrder.indexOf(bToPass) ? 1 : -1;
            }

            return sortingOrder.indexOf(aToPass) <= sortingOrder.indexOf(bToPass) ? 1 : -1;
          };
        },
        pescheck(ascending) {
          return (a, b) => {
            const aPes = a.pescheck?.status || 'none';
            const bPes = b.pescheck?.status || 'none';
            if (aPes === bPes) {
              return 0;
            }
            const sortingOrder = [
              PeschecScreeningStatus.WARNING,
              PeschecScreeningStatus.OPEN,
              PeschecScreeningStatus.FINISHED,
              'none',
            ];
            if (ascending) {
              return sortingOrder.indexOf(aPes) > sortingOrder.indexOf(bPes) ? 1 : -1;
            }

            return sortingOrder.indexOf(aPes) <= sortingOrder.indexOf(bPes) ? 1 : -1;
          };
        },
      },
    };
    showDownloadPDFModal: boolean = false;
    showModifyStatusModal: boolean = false;
    showPesCheckModal: boolean = false;
    showChangesRequestedModal: boolean = false;
    showHardDeleteModel: boolean = false;
    selectedUser: any = null;
    UserTier = UserTier;
    currentTierFilter: TierFilter = 'all';

    @Getter getUserIdentificationStatus!: Function;
    @Ref('investorTable') investorTable!: any;

    @Prop({ default: false }) loadingBindings!: boolean;

    @Getter getCurrentManager!: CurrentManager;
    @Action(addToastMessage) addToastMessage!: Function;
    @Action requestPescheck!: Function;

    @Getter getCurrentManagerRole!: ManagerRole;
    @Getter getIdentificationRequestFromInvestorsListByInvestorId!: (id: string) => IdentificationRequest | undefined;
    @Getter getPendingChangeRequests!: State['dataChangeRequests'];

    @StateClass('pescheck') statePescheck!: State['pescheck'];
    @StateClass boundUser!: State['boundUser'];
    @StateClass boundUsers!: Investor[];

    @Watch('statePescheck.status')
    onPescheckStatusChange(newPescheckStatus: DataContainerStatus): void {
      if (newPescheckStatus === DataContainerStatus.Success) {
        this.addToastMessage({
          text: 'The request has been suscessfully sent to get reviewed. You will receive the new result shortly.',
          type: 'success',
        });
      } else if (newPescheckStatus === DataContainerStatus.Error) {
        this.addToastMessage({
          text: 'Pescheck reviewing request failed. Please contact support.',
          type: 'danger',
        });
      }
    }

    /**
     * Execute investors download.
     */
    async exportInvestors(): Promise<void> {
      const [getInvestmentsError, getInvestmentsSuccess] = await to(
        bloqifyFirestore.collection('investments').get(),
      );

      if (getInvestmentsError) {
        this.addToastMessage({
          text: 'Something went wrong while fetching the investments for the selected asset',
          type: 'danger',
        });
        return;
      }

      const investorsWithInvestments = (await Promise.all(getInvestmentsSuccess!.docs.map(async (doc) => {
        const [getInvestorError, getInvestorSuccess] = await to(
          bloqifyFirestore.collection('investors').doc(doc.get('investor').id).get(),
        );

        if (getInvestorError) {
          this.addToastMessage({
            text: 'Something went wrong while fetching the investor for an investment',
            type: 'danger',
          });
        }

        const [getAssetError, getAssetSuccess] = await to(
          bloqifyFirestore.collection('assets').doc(doc.get('asset').id).get(),
        );

        if (getAssetError) {
          this.addToastMessage({
            text: 'Something went wrong while fetching the asset for an investment',
            type: 'danger',
          });
        }

        return {
          id: getInvestorSuccess!.get('customId'),
          Name: getInvestorSuccess!.get('name'),
          Surname: getInvestorSuccess!.get('surname'),
          Email: getInvestorSuccess!.get('email'),
          Fund: getAssetSuccess!.get('name'),
          'Total Bought Shares': doc.get('boughtSharesTotal'),
          'Total Paid Euro': doc.get('paidEuroTotal'),
          'Bank Account': getInvestorSuccess!.get('bankAccount'),
          Telephone: getInvestorSuccess!.get('telephone'),
          'Street Address': getInvestorSuccess!.get('streetAddress'),
          'House Number': getInvestorSuccess!.get('houseNumber'),
          City: getInvestorSuccess!.get('city'),
          'Postal Code': getInvestorSuccess!.get('postalCode'),
          Nationality: getInvestorSuccess!.get('nationality'),
          Country: getInvestorSuccess!.get('country'),
          'National Register Number': getInvestorSuccess!.get('nationalRegisterNumber'),
        };
      }))).sort(({ id }) => id);

      const BOM = '\uFEFF';
      const csv = BOM + json2csv.parse(investorsWithInvestments);

      // Present file download
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      FileSaver.saveAs(blob, 'users.csv');
    }

    handleModal(modal: 'PesCheckModal' | 'modify' | 'download' | 'changeRequest' | 'hardDelete', status: boolean, investor: any): void {
      this.selectedUser = investor;
      switch (modal) {
      case 'modify':
        this.showModifyStatusModal = status;
        break;
      case 'download':
        this.showDownloadPDFModal = status;
        break;
      case 'PesCheckModal':
        this.showPesCheckModal = status;
        break;
      case 'changeRequest':
        this.showChangesRequestedModal = status;
        break;
      case 'hardDelete':
        this.showHardDeleteModel = status;
        break;
      default:
        break;
      }
    }

    get listUsers(): (UserDisplayTable | null)[] {
      return this.boundUsers.map((investor: User | Investor): any | null => {
        const userIRstatus: GetUserIdentificationStatus = this.getUserIdentificationStatus(investor);

        let pescheck: PescheckDisplay | UserData = null;
        const isPescheckObject = (pescheck): pescheck is Pescheckv3PescheckDocument => !!pescheck.finalResult?.status;
        const pescheckOfUser = (investor as User).pescheck;
        if (pescheckOfUser && isPescheckObject(pescheckOfUser)) {
          pescheck = {
            status: pescheckOfUser.finalResult?.status,
            datasets: pescheckOfUser.finalResult?.watchlist.results.matches.map((match): any => match.datasets).flat(),
            email: investor.email,
            first_name: (investor as Investor).name,
            last_name: (investor as Investor).surname,
            watchlist_date_of_birth: moment((investor as Investor).dateOfBirth).format('YYYY-MM-DD'),
            watchlist_notes: pescheckOfUser.initialRequest?.id,
            isFalsePositiveScreening: pescheckOfUser.finalResult?.isFalsePositiveScreening,
            isFalsePositivePep: pescheckOfUser.finalResult?.isFalsePositivePep,
          };
        }

        // Filter data change requests for this user
        const changeRequests = this.getPendingChangeRequests.filter(
          (dataChangeRequest: DataChangeRequest) => (dataChangeRequest.investor as User).id === (investor as User).id,
        ).length;

        // Create string for createdDateTime to be able to filter on it
        const createdDateTimeString: string = this.$options.filters?.transformDate(
          this.$options.filters?.convertUTCToLocalDate((investor as User).createdDateTime),
          'DD/MM/YYYY',
        );

        const user = {
          ...investor,
          pescheck,
          userIRstatus,
          id: (investor as UserDisplayTable).id,
          changeRequests: changeRequests || false,
          createdDateTimeString,
        };

        // Combines values from predefined fields into string for filtering
        const combinedValues = deepValues(user, this.combinedQueryFields) as string;

        return {
          ...user,
          combinedValues,
        };
      });
    }

    get pescheckWarning(): any {
      return PeschecScreeningStatus.WARNING;
    }

    get pescheckCompleted(): any {
      return PeschecScreeningStatus.FINISHED;
    }

    get user(): User | Investor | null {
      return this.boundUser ?? null;
    }

    /**
     * Returns whether current browser allows file downloads.
     */
    get fileDownloadIsSupported(): boolean {
      try {
        return !!new Blob();
      } catch (e) {
        return false;
      }
    }

    submitPescheckReview(pescheck: any): void {
      const userData = {
        email: pescheck.email,
        first_name: (pescheck as Investor).name,
        last_name: (pescheck as Investor).surname,
        watchlist_date_of_birth: moment((pescheck as Investor).dateOfBirth).format('YYYY-MM-DD'),
        watchlist_notes: pescheck.pescheck?.watchlist_notes,
      };

      this.requestPescheck({ pescheck: userData, investorId: pescheck.id });
    }

    /**
     * Allow changing investor status (enabled/disabled).
     */
    get allowChangingInvestorStatus(): boolean {
      return this.getCurrentManagerRole === ManagerRole.Superadmin || this.getCurrentManagerRole === ManagerRole.Admin;
    }

    get currentManagerId(): string {
      return this.getCurrentManager.uid;
    }

    get allowDeletingInvestor(): boolean {
      return this.getCurrentManagerRole === ManagerRole.Superadmin;
    }

    filterTableByTier(tier: TierFilter) {
      this.currentTierFilter = tier;
      if (tier === 'all') {
        Event.$emit('vue-tables.filter::tier', null);
        return;
      }
      Event.$emit('vue-tables.filter::tier', tier);
    }
  }
