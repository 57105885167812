
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { Action, State as StateClass } from 'vuex-class';
  // @ts-ignore
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { State } from '@/models/State';
  import { DataContainerStatus } from '@/models/Common';
  import Modal from '@/components/common/Modal.vue';
  import { UserStatus } from '@/models/users/User';
  import { bloqifyFirestore } from '@/boot/firebase';

  @Component({
    components: {
      Modal,
    },
  })
  export default class ModifyStatusModal extends Vue {
    statusMessage: string = '';

    @Action bindFirestoreReferences!: Function;
    @Action handleUserStatus!: Function;
    @Action(addToastMessage) addToastMessage!: Function;

    @StateClass('user') userSlice!: State['user'];

    @Prop({ default: undefined }) user!: any;
    @Prop({ default: undefined }) userId!: any;
    @Prop({ default: undefined }) managerId!: any;

    @Watch('userSlice.status')
    onUserStatusChanged(newStatus: DataContainerStatus): void {
      // Error
      if (newStatus === DataContainerStatus.Error) {
        this.addToastMessage({
          text: this.userSlice!.error,
          type: 'danger',
        });
      }

      // Success
      if (newStatus === DataContainerStatus.Success) {
        this.bindFirestoreReferences([
          {
            name: 'boundUser',
            ref: bloqifyFirestore.collection('investors').doc(this.userId),
          },
        ]);
        let message: string = `User has been ${this.userSlice!.payload ? this.userSlice!.payload.status : 'modified'}.`;
        message += this.userSlice!.payload.status === UserStatus.Disabled ? ' He/she can no longer use the Bloqify application.' : '';

        // Notification
        this.addToastMessage({
          text: message,
          type: 'success',
        });
      }

      if (newStatus === DataContainerStatus.Error) {
        this.$emit('close');
      }
      if (newStatus === DataContainerStatus.Success) {
        this.$emit('close', 'success');
      }
    }

    get userIsEnabled(): boolean {
      return this.user.status === UserStatus.Enabled;
    }

    /**
     * New status value.
     */
    get newStatus(): string {
      return this.userIsEnabled ? UserStatus.Disabled : UserStatus.Enabled;
    }

    /**
     * Disabled message.
     */
    get statusDisabledMessage(): string | undefined {
      if (this.statusMessage === '') {
        return undefined;
      }

      return this.statusMessage;
    }

    /**
     * Returns if we are doing something.
     */
    get isProcessing(): boolean {
      return this.userSlice?.status === DataContainerStatus.Processing;
    }
  }
